<template>
  <div id="companyLogin" v-loading="loading">
    <div v-if="userLoginFlag" id="navigation-right">
      <div class="user-info">
        <div class="user-avatar left">
          <img src="../../assets/image/pc/头像@2x.png" class="w-100 h-100" alt="">
        </div>
        <div v-if="!userLoginFlag" style="display: flex;align-items: center;justify-content: left;height: 100%">
          &nbsp;&nbsp;&nbsp;<a class="helloLogin" :href="this.memberThree+'/ebidding/#/login'">您好，请登录/注册</a>
        </div>
        <div class="user-info-details left">
          <div v-if="userLoginFlag" class="user-name show-one-line">
          <span>
            {{ userInfo.trueName }}
          </span>
            <span v-if="!userLoginFlag">&nbsp;</span>
          </div>
          <div v-if="userLoginFlag" class="user-company show-one-line">
          <span>
            {{ userInfo.companyName }}
          </span>
          </div>
        </div>
      </div>
      <el-row v-if="userLoginFlag && userInfo.isSyncVc =='1' && company.companyId =='2540'">
        <el-col :span="24" class="col-user-left1">
          <img class="col-user-image" src="../../assets/image/new-home/icon_tbf.png">
          <a class="col-user-span" :href="this.member+'/member/login/ruoyiBlank.htm'">数字化采购专区</a>
        </el-col>
      </el-row>
      <div class="operate-guide">
        <div class="operate-image left">
          <img src="../../assets/image/pc/指南针@2x.png" class="w-100 h-100" alt="">
        </div>
        <div class="operate-title left">
          操作指南
        </div>
      </div>
      <div class="operate-content">
        <el-row>
          <el-col v-for="(item ,index) in operateGuide.children" :key="'item4'+index" v-show="item.isIssue=='0'"
                  class="operate-content-item" :span="12">
            <label class="point">&nbsp;</label>
            <router-link :to="item.categoryUrl">{{ item.categoryTitle }}</router-link>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-form v-if="!userLoginFlag" :model="loginUser" ref="numberValidateForm" class="demo-ruleForm companyLoginForm"
             :rules="loginFormRules">
      <el-tabs :stretch="true" v-model="loginUser.loginType">
        <el-tab-pane label="密码登录" name="0">
          <el-form-item v-if="loginUser.loginType == 0"
                        prop="username">
            <el-input type="age" prefix-icon="el-icon-user"
                      placeholder="请输入手机/邮箱/用户名 "
                      v-model="loginUser.username" autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item v-if="loginUser.loginType == 0"
                        prop="xstr">
            <el-input type="password" prefix-icon="el-icon-unlock"
                      placeholder="请输入密码"
                      v-model="loginUser.xstr" autocomplete="off"></el-input>
          </el-form-item>
          <!--  验证码   -->
          <el-form-item prop="captcha" v-if="loginUser.loginType == 0">
            <el-row style="display: flex !important;">
              <el-col :lg="16" :span="14" style="flex: 1 !important;">
                <el-input prefix-icon="el-icon-unlock"
                          placeholder="请输入验证码"
                          v-model="loginUser.captcha" autocomplete="off"></el-input>
              </el-col>
              <el-col :lg="8" :span="10" style="width: 80px !important;">
                <div v-html="str"
                     class="checkImg" @click="createImg()"></div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="短信登录" name="4">
          <el-form-item v-if="loginUser.loginType == 4" prop="mobile">
            <el-input type="text" prefix-icon="el-icon-phone"
                      placeholder="请输入手机号码"
                      maxlength="11"
                      v-model="loginUser.mobile" autocomplete="off">
              <template slot="suffix">
                <span class="mobile-button" v-if="countdown == 0" @click="sendMobileCaptcha">获取验证码</span>
                <span class="mobile-button" v-else>{{ countdown }}秒后可重试</span>
              </template>
              <!--          <i  class="el-icon-date" @click="sendMobileCaptcha"></i>-->
            </el-input>
          </el-form-item>
          <el-form-item v-if="loginUser.loginType == 4" prop="captcha2">
            <el-input
                prefix-icon="el-icon-unlock"
                type="text"
                maxlength="6"
                placeholder="请输入验证码"
                v-model="loginUser.captcha2">
            </el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <el-form-item size="mini" style="margin-top: -5px;margin-bottom: 0px ">
        <el-row>
          <el-col :lg="18" :sm="24">
            <el-checkbox v-model="loginUser.read"></el-checkbox>&nbsp;
            <span style="font-size: 1.3rem">我已阅读并接受 <a
                :href="this.member+'/member/sysreg/viewRegister.htm?isAudit=1&no_sitemesh'">用户协议</a></span>
          </el-col>
          <el-col :lg="6" class="hidden-md-and-down">
            <span style="font-size: 1.3rem"><a :href="this.member+'/member/user/findPassword.htm?no_sitemesh'">忘记密码</a></span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item style="margin-bottom: 0px" class="login-button">
        <el-row>
          <el-col :span="24">
            <el-button v-if="loginUser.loginType == 0" style="width: 100%;background: #D10000;" type="danger"
                       @click="submitForm('numberValidateForm')">
              登录
            </el-button>
            <el-button v-if="loginUser.loginType == 4" style="width: 100%;background: #D10000;" type="danger"
                       @click="smsLogin('numberValidateForm')">
              登录
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="hidden-md-and-down">
        <el-row>
          <el-col :span="24" style="display: flex;justify-content: end">
            <a :href="this.member+'/member/sysreg/register.htm?isAudit=1&no_sitemesh'">注册新账号</a>
          </el-col>
        </el-row>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import jsencrypt from "jsencrypt";
import {getUserInfo} from "../../api/commoon/user";
import qs from 'qs'

export default {
  name: "companyLogin",
  props: ['company'],
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }
    let checkCaptcha = (rule, value, callback) => {
      let reg = /^\d{6}$/
      if (!reg.test(value)) {
        callback(new Error('请输入6位数字验证码'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      countdown: 0,
      loginUser: {
        username: "",
        xstr: '',
        captcha: "",
        caKey: "",
        loginType: "0",
        checkCaFlag: "",
        agent: "",
        openid: "",
        unionid: "",
        errorMessage: "",
        loginFlag: 0,
        read: "",
        mobile: null,
        captcha2: null,
      },
      operateGuide: {},
      userLoginFlag: false,
      loginFormRules: {
        // 验证用户名
        username: [
          {required: true, message: '请输入手机/邮箱/用户名', trigger: 'blur'},
        ],
        // 验证密码
        xstr: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
        ],// 验证码
        captcha: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {type: 'number', validator: checkPhone, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        captcha2: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {type: 'number', validator: checkCaptcha, message: '请输入6位数字验证码', trigger: 'blur'}
        ]
      },
      userInfo: {},
      str: "",
    };
  },
  created() {
    let out = this.getParameter("logout");
    //整个url
    let url = document.location.toString();
    if (out != null && out != undefined && out != ""){
      this.$http.post(this.member+"/member/login/logout").then(res=>{
        console.log(res);
      })
      //去除掉logout参数
      document.location = url.replace("logout=1","");
      //重新加载页面
      window.reload();
    }
    //验证码的是否显示
    this.createImg();
    this.selectCategory("operate_guide", "operateGuide");
    this.checkUser(this.getCookie("uuid"));
  },
  methods: {
    selectCategory: function (modelId, name) {
      var that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      }).catch(function (error) {

      })
    },
    getParameter: function (paraName) {
      let url = document.location.toString();
      //去除路由的#号
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      let arrObj = url.split("?");
      if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&");
        let arr;
        for (let i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getLoginFlag() {
      let that = this;
      axios.post(that.member + "/member/login/getLoginFlag.htm").then((data) => {
        that.loginUser.loginFlag = data.data.loginFlag;
      })
    },
    createImg() {
      let that = this;
      let str = Math.floor(Math.random() * 10000);
      str = that.member + "/member/login/captcha.htm?rnd-" + str;
      this.str = "<img src='" + str + "' style='border-radius: 4px;'>";
    },
    submitForm(formName) {
      let that = this;
      if (!that.loginUser.read) {
        that.$message.error('请阅读并接受用户协议');
      } else {
        this.$refs[formName].validate((valid) => {
          that.loginUser.xstr = that.encrypt(that.loginUser.xstr);
          if (valid) {
            that.loading = true;
            axios.post(that.member + "/member/login/executeVCLogin.htm", that.loginUser).then((data) => {
              that.loginUser.loginFlag = data.data.loginFlag;
              if (data.data.success == '1') {
                that.loading = false;
                location.href = that.cgzxUrl + '/index?expiresTime=' + data.data.expiresTime + '&tokenId=' + data.data.tokenId + '&sessionId=' + data.data.sessionId;
                //登录成功！！
                that.$message.info(data.data.message);
                this.checkUser(this.getCookie("uuid"));
              } else {
                that.loginUser.loginFlag = data.data.loginFlag;
                that.createImg();
                that.$message.error(data.data.message);
              }
            }).finally(res =>{
              that.loading = false;
            })
          } else {
            console.log('error submit!!');
          }
        });
      }

    },
    smsLogin(formName) {
      let that = this;
      if (!that.loginUser.read) {
        that.$message.error('请阅读并接受用户协议');
        return;
      }
      const url = that.member + "/member/login/smsLogin.htm";
      const options = {
        method: 'POST',
        data: qs.stringify(that.loginUser),
        url: url
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.loading = true;
          axios(options).then((data) => {
            that.loginUser.loginFlag = data.data.loginFlag;
            if (data.data.success == '1') {
              that.loading = false;
              location.href = that.cgzxUrl + '/index?expiresTime=' + data.data.expiresTime + '&tokenId=' + data.data.tokenId + '&sessionId=' + data.data.sessionId;
              //登录成功！！
              that.$message.info(data.data.message);
              this.checkUser(this.getCookie("uuid"));
            } else {
              that.loginUser.loginFlag = data.data.loginFlag;
              that.createImg();
              that.$message.error(data.data.message);
            }
          }).catch(res=>{
            that.loading = false;
          }).finally(res => {
            that.loading = false;
          })
        } else {
        }
      })
    },
    encrypt(str) {
      let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCzrjWyZGR7kNdlnjDYptEB9mEc10NU53muUM/0vbzD8ivekX5zuJ6P7VrjzT7VIu1r9U9B2+xXzSF+2tinEzBpE8z/DAeL235ZmNUQJFIVGvrGUYs4q7nj21n4qNlwfbjpEH2kPkBG3jgAeEHMXj4tkaI5Nb/6Kr+yCZpaSn2U+wIDAQAB";
      let encrypt = new jsencrypt();
      encrypt.setPublicKey(publicKey);
      if (!str) {
        return encrypt.encrypt(null);
      }
      let code = "";
      let lt = str.match(/.{1,117}/g);
      lt.forEach(function (entry) {
        code += encrypt.encrypt(entry);
      });
      if (lt.length > 1) {
        return '^' + code;
      } else {
        return code;
      }
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin == '1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
    sendMobileCaptcha: function () {
      //发送短信
      let that = this;
      that.loading = true;
      if (that.loginUser.mobile == null || that.loginUser.mobile == "") {
        that.$message.error("请输入手机号码！");
        that.loading = false;
        return;
      }
      axios.get(that.member + "/member/login/sendMobileCaptcha.html?no_sitemesh", {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
          mobile: that.loginUser.mobile
        }
      }).then((res) => {
        //提示消息
        let message = res.data.message;
        if (message == null || message == "" || message == undefined) {
          //设置倒计时
          that.countdown = res.data.expireTime;
          that.setTime();
        } else {
          that.$message.error(message);
        }
      }).finally(res => {
        that.loading = false;
      })
    },
    setTime() {
      let that = this;
      if (that.countdown == 0) {
        return false;
      } else {
        that.countdown--;
      }
      //验证码倒计时
      setTimeout(function () {
        that.setTime()
      }, 1000);
    }
  }
}
</script>

<style>
.companyLoginForm .el-tabs__header {
  margin: 0 0 5px 0px !important;
}

.companyLoginForm .is-active {
  color: rgb(209, 0, 0)  !important;
  border: none !important;
}

.companyLoginForm .el-tabs__item:hover {
  color: rgb(209, 0, 0)  !important;
}

.companyLoginForm .el-tabs__active-bar {
  background: linear-gradient(90deg, #E5000F 0%, #FF6207 100%);
}

.companyLoginForm .el-tabs__item {
  height: 30px;
  line-height: 25px;
}


.companyLoginForm .el-form-item {
  margin-bottom: 15px;
  line-height: 25px;
}

.companyLoginForm .el-form-item__error {
  padding-top: 1px;
  font-size: 11px;
}

.checkImg {
  display: flex;
  height: 40px;
  overflow: hidden;
  width: 100%;
  justify-content: end;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate
.el-checkbox__inner {
  background-color: #f78989;
  border-color: #f78989;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f78989;
}

.el-checkbox.is-bordered.is-checked {
  border-color: #f78989;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #f78989;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .el-form-item__error {
    font-size: 10px !important;
  }

  .login-button .el-button {
    font-size: 1.1rem;
  }

  .companyLoginForm .el-form-item {
    height: 35px !important;
  }

  .companyLoginForm .el-form-item__content {
    line-height: 30px !important;
  }

  .companyLoginForm .el-input__inner {
    height: 35px !important;
  }

  .checkImg {
    height: 35px;
  }
}
</style>
<style scoped>
#companyLogin {
  height: 100% !important;
}
.operate-content-item a {
  color: #8A5555;
}

.operate-content-item a:hover {
  color: #de0d30;
}

.user-entrance-item a {
  color: #D4000A;
}

.helloLogin {
  font-size: 1.4rem;
  color: #999999;
}

.col-user-left1 {
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-right: 0.5px solid #E5E5E5;
  border-bottom: 0.5px solid #E5E5E5;
}

.col-user-left2 {
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #E5E5E5;
}

.col-user-right1 {
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #E5E5E5;
  border-right: 0.5px solid #E5E5E5;
}

.col-user-right2 {
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #E5E5E5;
}

.col-user-image {
  height: 30px;
  width: 36px;
}

.col-user-span {
  width: 47px;
  height: 16px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}

.col-sm-6, col-md-6, .col-lg-6, .col-xl-6 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mobile-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(255, 98, 7);
  text-align: right;
  pointer-events: auto;
}

@media (min-width: 992px) and (max-width: 1200px) {
  #navigation-right {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }

  .helloLogin {
    font-size: 1.3rem;
    color: #999999;
  }

  .user-info {
    width: 100%;
    height: 52px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 10px 10px 10px 10px;
  }

  .user-avatar {
    width: 36px;
    height: 36px;
  }

  .user-info-details {
    width: 120px;
    margin-left: 10px;
  }

  .user-name {
    line-height: 16px;
    font-size: 1.1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }

  .user-entrance {
    height: 80px;
  }

  .operate-guide {
    height: 20px;
  }

  .operate-image {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: 6px;
  }

  .operate-title {
    font-size: 1.4rem;
    margin-left: 4px;
    padding-top: 1px;
  }

  .operate-content {
    width: 179px;
    height: 87px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 1.1rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 13px;;
    margin: 5px 8px 8px 8px;
  }

  .operate-content-item {
    line-height: 20px;
    cursor: pointer;
    padding-left: 14px;
    margin-top: 5px;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }

  .col-user-left1 {
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-right: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-left2 {
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-right1 {
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
  }

  .col-user-right2 {
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-image {
    height: 25px;
    width: 27px;
  }

  .col-user-span {
    width: 47px;
    height: 16px;
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .mobile-button {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #navigation-right {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }

  .helloLogin {
    font-size: 1rem;
    color: #999999;
  }

  .user-info {
    width: 100%;
    height: 50px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 0px 8px 0px 14px;
  }

  .user-avatar {
    width: 26px;
    height: 26px;
    margin-top: 10px;
  }

  .user-info-details {
    width: 80px;
    margin-left: 10px;
  }

  .user-name {
    line-height: 25px;
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .user-entrance {
    height: 50px;
  }

  .operate-guide {
    height: 15px;
  }

  .operate-image {
    width: 15px;
    height: 15px;
  }

  .operate-title {
    font-size: 1rem;
    margin-left: 10px;
  }

  .operate-content {
    width: 132px;
    height: 64px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 0.9rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 15px;;
    padding: 5px 0px 0px 6px;
  }

  .operate-content-item {
    line-height: 20px;
    cursor: pointer;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }

  .col-user-left1 {
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-right: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-left2 {
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-right1 {
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
  }

  .col-user-right2 {
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-image {
    height: 20px;
    width: 22px;
  }

  .col-user-span {
    width: 30px;
    height: 16px;
    font-size: 8px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
  #companyLogin {
    height: auto !important;
    background: white !important;
    padding: 5px 10px;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  #navigation-right {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }

  .user-info {
    width: 100%;
    height: 62px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 10px 10px 5px 10px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
  }

  .user-info-details {
    width: 120px;
    margin-left: 10px;
  }

  .user-name {
    line-height: 25px;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1.2rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .user-entrance {
    height: 80px;
  }

  .operate-guide {
    height: 20px;
  }

  .operate-image {
    width: 20px;
    height: 20px;
  }

  .operate-title {
    font-size: 1.4rem;
    margin-left: 10px;
  }

  .operate-content {
    width: 90%;
    height: 75px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 1.1rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 13px;;
    margin: 5px 5% 5% 5%;
  }

  .operate-content-item {
    line-height: 20px;
    cursor: pointer;
    margin-top: 4px;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }

  .col-user-left1 {
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-right: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-left2 {
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-right1 {
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
  }

  .col-user-right2 {
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }

  .col-user-image {
    height: 20px;
    width: 22px;
  }

  .col-user-span {
    width: 30px;
    height: 16px;
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
}

@media (min-width: 1200px) {

  .mobile-button {
    font-size: 12px;
  }

  #navigation-right {
    width: 232px;
    height: 100%;
    background: #FFFFFF;
  }

  .user-info {
    width: 232px;
    height: 67px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 10px 10px 10px 10px;
  }

  .user-avatar {
    width: 42px;
    height: 42px;
  }

  .user-info-details {
    width: 150px;
    margin-left: 20px;
  }

  .user-name {
    line-height: 25px;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1.2rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .user-entrance {
    height: 85px;
  }

  .operate-guide {
    height: 30px;
    padding-top: 5px;
  }

  .operate-image {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: 6px;
  }

  .operate-title {
    font-size: 1.4rem;
    margin-left: 4px;
    padding-top: 2px;
  }

  .operate-content {
    width: 213px;
    height: 103px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 1.2rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 15px;;
    margin: 5px 10px 10px 10px;
  }


  .operate-content-item {
    line-height: 30px;
    cursor: pointer;
    padding-left: 16px;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }
}


@media (min-width: 576px ) and (max-width: 768px) {
  #companyLogin {
    height: auto !important;
    background: white !important;
    padding: 5px 10px;
    border-radius: 10px;
  }
}

/*入口*/
</style>
