<template>
  <div id="navigation-right">
    <div class="user-info">
      <div class="user-avatar left">
        <img src="../../assets/image/pc/头像@2x.png" class="w-100 h-100" alt="">
      </div>
      <div v-if="!userLoginFlag" style="display: flex;align-items: center;justify-content: left;height: 100%">
        &nbsp;&nbsp;&nbsp;<a class="helloLogin" :href="this.memberThree+'/ebidding/#/login'">您好，请登录/注册</a>
      </div>
      <div class="user-info-details left">
        <div v-if="userLoginFlag" class="user-name show-one-line">
          <span>
            {{ userInfo.trueName }}
          </span>
          <span v-if="!userLoginFlag">&nbsp;</span>
        </div>
        <div v-if="userLoginFlag" class="user-company show-one-line">
          <span>
            {{ userInfo.companyName }}
          </span>
        </div>
      </div>
    </div>
    <div class="user-entrance">
      <div class="col-user-left1 left col-sm-6 col-md-6 col-lg-6 col-xl-6" v-on:click="login()">
          <img class="col-user-image" src="../../assets/image/new-home/icon_tbf.png">
          <span class="col-user-span">招标方入口</span>
      </div>
      <div class="col-user-left2 left col-sm-6 col-md-6 col-lg-6 col-xl-6" v-on:click="login()">
          <img class="col-user-image" src="../../assets/image/new-home/icon_cgf.png">
          <span class="col-user-span">投标方入口</span>
      </div>
      <div class="col-user-right1 left col-sm-6 col-md-6 col-lg-6 col-xl-6" v-on:click="login()">
          <img class="col-user-image" src="../../assets/image/new-home/icon_dls.png">
          <span class="col-user-span">代理机构入口</span>
      </div>
      <div class="col-user-right2 left col-sm-6 col-md-6 col-lg-6 col-xl-6" v-on:click="login()">
          <img class="col-user-image" src="../../assets/image/new-home/icon_zj.png">
          <span class="col-user-span">评标专家入口</span>
      </div>
    </div>
    <div class="operate-guide">
      <div class="operate-image left">
        <img src="../../assets/image/pc/指南针@2x.png" class="w-100 h-100" alt="">
      </div>
      <div class="operate-title left">
        操作指南
      </div>
    </div>
    <div class="operate-content">
      <el-row>
        <el-col v-for="(item ,index) in operateGuide.children" :key="'item'+index" class="operate-content-item" :span="12" >
          <label class="point">&nbsp;</label>
          <router-link :to="item.categoryUrl">{{ item.categoryTitle }}</router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "navigation-right",
  props:["company"],
  data() {
    return {
      userInfo: {},
      userLoginFlag: false,
      operateGuide:{},
    }
  },
  methods: {
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      var that = this;
      var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
    login() {
      location.href = this.memberThree + "/ebidding/#/login";
    },
    selectCategory: function (modelId, name) {
      var that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      }).catch(function (error) {

      })
    },
  },
  created() {
    this.checkUser(this.getCookie("uuid"));
    this.selectCategory("operate_guide", "operateGuide");
  }
}
</script>

<style scoped>
.operate-content-item a {
  color: #8A5555;
}

.operate-content-item a:hover {
  color: #de0d30;
}

.user-entrance-item a {
  color: #D4000A;
}
.helloLogin{
  font-size: 1.4rem;
  color: #999999;
}
.col-user-left1{
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-right: 0.5px solid #E5E5E5;
  border-bottom: 0.5px solid #E5E5E5;
}
.col-user-left2{
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #E5E5E5;
}
.col-user-right1{
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #E5E5E5;
  border-right: 0.5px solid #E5E5E5;
}
.col-user-right2{
  height: 50%;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #E5E5E5;
}
.col-user-image{
  height: 30px;
  width: 36px;
}
.col-user-span{
  width: 47px;
  height: 16px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.col-sm-6,col-md-6,.col-lg-6,.col-xl-6{
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@media (min-width: 992px) and (max-width: 1200px) {
  #navigation-right {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }
  .helloLogin{
    font-size: 1.3rem;
    color: #999999;
  }
  .user-info {
    width: 100%;
    height: 52px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 10px 10px 10px 10px;
  }

  .user-avatar {
    width: 36px;
    height: 36px;
  }

  .user-info-details {
    width: 120px;
    margin-left: 10px;
  }

  .user-name {
    line-height: 16px;
    font-size: 1.1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }

  .user-entrance {
    height: 80px;
  }

  .operate-guide {
    height: 20px;
  }

  .operate-image {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: 6px;
  }

  .operate-title {
    font-size: 1.4rem;
    margin-left: 4px;
    padding-top: 1px;
  }

  .operate-content {
    width: 179px;
    height: 87px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 1.1rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 13px;;
    margin: 5px 8px 8px 8px;
  }

  .operate-content-item {
    line-height: 20px;
    cursor: pointer;
    padding-left: 14px;
    margin-top: 5px;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }
  .col-user-left1{
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-right: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-left2{
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-right1{
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
  }
  .col-user-right2{
    height: 50%;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-image{
    height: 25px;
    width: 27px;
  }
  .col-user-span{
    width: 47px;
    height: 16px;
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #navigation-right {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }
  .helloLogin{
    font-size: 1rem;
    color: #999999;
  }

  .user-info {
    width: 100%;
    height: 50px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 0px 8px 0px 14px;
  }

  .user-avatar {
    width: 26px;
    height: 26px;
    margin-top: 10px;
  }

  .user-info-details {
    width: 80px;
    margin-left: 10px;
  }

  .user-name {
    line-height: 25px;
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .user-entrance {
    height: 50px;
  }

  .operate-guide {
    height: 15px;
  }

  .operate-image {
    width: 15px;
    height: 15px;
  }

  .operate-title {
    font-size: 1rem;
    margin-left: 10px;
  }

  .operate-content {
    width: 132px;
    height: 64px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 0.9rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 15px;;
    padding: 5px 0px 0px 6px;
  }

  .operate-content-item {
    line-height: 20px;
    cursor: pointer;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }
  .col-user-left1{
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-right: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-left2{
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-right1{
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
  }
  .col-user-right2{
    height: 50%;
    padding-top: 0px;
    padding-left: 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-image{
    height: 20px;
    width: 22px;
  }
  .col-user-span{
    width: 30px;
    height: 16px;
    font-size: 8px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
}

@media (max-width: 768px) {
  #navigation-right {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }

  .user-info {
    width: 100%;
    height: 62px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 10px 10px 5px 10px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
  }

  .user-info-details {
    width: 120px;
    margin-left: 10px;
  }

  .user-name {
    line-height: 25px;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1.2rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .user-entrance {
    height: 80px;
  }

  .operate-guide {
    height: 20px;
  }

  .operate-image {
    width: 20px;
    height: 20px;
  }

  .operate-title {
    font-size: 1.4rem;
    margin-left: 10px;
  }

  .operate-content {
    width: 90%;
    height: 75px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 1.1rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 13px;;
    margin: 5px 5% 5% 5%;
  }

  .operate-content-item {
    line-height: 20px;
    cursor: pointer;
    margin-top: 4px;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }

  .col-user-left1{
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-right: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-left2{
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-right1{
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
  }
  .col-user-right2{
    height: 50%;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E5E5;
  }
  .col-user-image{
    height: 20px;
    width: 22px;
  }
  .col-user-span{
    width: 30px;
    height: 16px;
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
}

@media (min-width: 1200px) {

  #navigation-right {
    width: 232px;
    height: 100%;
    background: #FFFFFF;
  }

  .user-info {
    width: 232px;
    height: 67px;
    border-bottom: 0.5px solid #E6E6E6;
    padding: 10px 10px 10px 10px;
  }

  .user-avatar {
    width: 42px;
    height: 42px;
  }

  .user-info-details {
    width: 150px;
    margin-left: 20px;
  }

  .user-name {
    line-height: 25px;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }

  .user-company {
    font-size: 1.2rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .user-entrance {
    height: 85px;
  }
  .operate-guide {
    height: 30px;
    padding-top: 5px;
  }

  .operate-image {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: 6px;
  }

  .operate-title {
    font-size: 1.4rem;
    margin-left: 4px;
    padding-top: 2px;
  }

  .operate-content {
    width: 213px;
    height: 103px;
    background: #FFF0F1;
    margin-top: 3px;
    font-size: 1.2rem;
    font-family: MicrosoftYaHei;
    color: #8A5555;
    line-height: 15px;;
    margin: 5px 10px 10px 10px;
  }


  .operate-content-item {
    line-height: 30px;
    cursor: pointer;
    padding-left: 16px;
  }

  .point {
    border-radius: 10px;
    background: red;
    color: red;
    line-height: 3px;
    margin-right: 3px;
  }
}

/*入口*/
</style>
